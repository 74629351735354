import api from "api";

const API_ROUTE = "organizations/api/v1/organizations";

export type Organization = {
  id: number;
  version: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  deletedAt: string | null;
};

export type Workspace = {
  id: number;
  version: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  deletedAt: string | null;
  organizationId: number;
  createdBy: string;
};

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWorkspaces: build.query<
      {
        data: Workspace[];
      },
      { organizationId: number }
    >({
      query: ({ organizationId }) => ({
        url: `${API_ROUTE}/${organizationId}/projects`,
      }),
    }),
  }),
});

export const { useGetWorkspacesQuery, useLazyGetWorkspacesQuery } = extendedApi;
