import React from "react";
import { useSelector } from "react-redux";
import { components } from "@tsc/component-library/lib/commonTheme";
import { Autocomplete, TextField } from "@tsc/component-library/lib/components";
import lodash from "lodash";

import { selectCountries } from "features/classification/classificationSlice";

const CountryDropdown = ({
  label,
  value,
  placeholder,
  disabled,
  disableClearable,
  fullWidth,
  multiple,
  onChange,
}) => {
  const countries = useSelector(selectCountries);
  const options = lodash
    .chain(countries)
    .keys()
    .sortBy((iso) => countries[iso]?.name)
    .value();
  return (
    <Autocomplete
      fullWidth={fullWidth}
      multiple={multiple}
      disableClearable={disableClearable}
      size="small"
      value={value}
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => countries[option]?.name}
      onChange={(_, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...components.MuiTextField.defaultProps.InputLabelProps,
          }}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default CountryDropdown;
