import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { components } from "@tsc/component-library/lib/commonTheme";
import { Autocomplete, TextField } from "@tsc/component-library/lib/components";
import lodash, { isFunction } from "lodash";

import { selectSubcountries } from "features/classification/classificationSlice";

const SubcountryDropdown = ({
  label,
  value,
  placeholder,
  disabled,
  disableClearable,
  fullWidth,
  multiple,
  onChange,
  filterOptions,
}) => {
  const subcountries = useSelector(selectSubcountries);
  const [subcountryIds, subcountryDictionaries] = useMemo(() => {
    const subcountryDictionaries = lodash
      .chain(subcountries)
      .flatMap(
        (subcountries, countryId) =>
          subcountries?.map((subcountry) => ({ ...subcountry, countryId })) ??
          []
      )
      .keyBy("iso_code")
      .value();
    const subcountryIds = lodash
      .chain(subcountryDictionaries)
      .keys()
      .uniq()
      .sortBy((option) => subcountryDictionaries[option].name)
      .value();
    return [subcountryIds, subcountryDictionaries];
  }, [subcountries]);
  return (
    <Autocomplete
      disableCloseOnSelect
      fullWidth={fullWidth}
      multiple={multiple}
      disableClearable={disableClearable}
      size="small"
      value={value}
      disabled={disabled}
      options={subcountryIds}
      getOptionKey={(option) => option}
      getOptionLabel={(option) => subcountryDictionaries[option]?.name}
      onChange={(_, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...components.MuiTextField.defaultProps.InputLabelProps,
          }}
          label={label}
          placeholder={placeholder}
        />
      )}
      filterOptions={
        isFunction(filterOptions)
          ? (options, state) =>
              filterOptions(options, state, subcountryDictionaries)
          : undefined
      }
    />
  );
};

export default SubcountryDropdown;
