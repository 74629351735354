import { createSelector, createSlice } from "@reduxjs/toolkit";
import { sortBy } from "lodash";

const initialState = {
  countries: {},
  subcountries: {},
  industries: [],
};

export const classificationSlice = createSlice({
  name: "classification",
  initialState,
  reducers: {
    setCountries: (state, { payload: { countries } }) => {
      state.countries = countries;
    },
    setSubcountries: (state, { payload: { subcountries } }) => {
      state.subcountries = subcountries;
    },
    setIndustries: (state, { payload: { industries } }) => {
      state.industries = industries;
    },
    setCategories: (state, { payload: { categories } }) => {
      state.categories = categories;
    },
  },
});

export const { setCountries, setSubcountries, setIndustries, setCategories } =
  classificationSlice.actions;

export const selectCountries = (state) => state.classification.countries;
export const selectSubcountries = (state) => state.classification.subcountries;
export const selectIndustries = (state) => state.classification.industries;
export const selectCategories = (state) => state.classification.categories;

export const selectCountriesAndSubcountries = createSelector(
  selectCountries,
  selectSubcountries,
  (countries, subcountries) => {
    let map = new Map();

    const sortedCountries = sortBy(
      Object.entries(countries).map(([key, value]) => ({
        countryCode: key,
        ...value,
      })),
      "name"
    );

    sortedCountries.forEach((country) => {
      map.set(country.countryCode, country.name);

      const matchedSubcountries = sortBy(
        subcountries[country.countryCode],
        "name"
      );
      matchedSubcountries?.forEach((subcountry) => {
        map.set(subcountry.iso_code, subcountry.name);
      });
    });

    return { array: Array.from(map.keys()), map };
  }
);

export default classificationSlice.reducer;
