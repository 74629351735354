import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  SvgIcon,
  Toolbar,
  Tooltip,
} from "@tsc/component-library/lib/components";
import { QuickAccessMenu } from "@tsc/component-library/lib/components/QuickAccessMenu";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import queryString from "query-string";

import ProfileMenu from "components/ProfileMenu/ProfileMenu";
import VerticalDivider from "components/VerticalDivider/VerticalDivider";
import { paths } from "configurations/router";
import { checkGlobalManagementRolePermission } from "utilities/data";

import { ReactComponent as TSCLogoText } from "../../assets/vectors/tsc_logo_text.svg";
import {
  selectPermissions,
  selectUser,
} from "../../features/authentication/authenticationSlice";

export const topMenuHeight = "64px";

const TopNav = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const profileMenuOpen = Boolean(profileMenuAnchor);
  const handleOpenProfileMenu = (event) =>
    setProfileMenuAnchor(event.currentTarget);
  const handleCloseProfileMenu = () => setProfileMenuAnchor(null);
  const userPermissions = useSelector(selectPermissions);

  const quickaccessOptions = buildQuickAccessOptions(userPermissions, navigate);

  if (!user) return null;
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "white",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar disableGutters sx={{ px: "8px", alignItems: "center" }}>
        <Box p={1}>
          <Link to="/">
            <SvgIcon
              component={TSCLogoText}
              viewBox="0 0 67 39"
              sx={{ width: "67px", height: "39px", ml: "6px", mt: "1px" }}
            />
          </Link>
        </Box>
        <Box minWidth={{ xs: "8px", sm: "56px" }} flexGrow={1} />
        <Tooltip title="Notifications">
          <IconButton>
            <Badge badgeContent={1} overlap="circular" color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <VerticalDivider />
        {quickaccessOptions.length > 0 && (
          <QuickAccessMenu options={quickaccessOptions} />
        )}
        <VerticalDivider />
        <Tooltip title="Profile & Account Settings">
          <IconButton onClick={handleOpenProfileMenu}>
            <Avatar
              src={user.photoURL}
              imgProps={{ referrerPolicy: "no-referrer" }}
              sx={{ width: 32, height: 32 }}
            />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <ProfileMenu
        anchor={profileMenuAnchor}
        open={profileMenuOpen}
        onClose={handleCloseProfileMenu}
      />
    </AppBar>
  );
};

export default TopNav;

const buildQuickAccessOptions = (userPermissions, onClick) => {
  const options = [
    {
      title: "Issues",
      icon: PreviewOutlinedIcon,
      onClick: () => onClick("/issues"),
    },
    {
      title: "RBAC",
      icon: LockPersonIcon,
      onClick: () => onClick(`/rbac`),
    },
    {
      title: "Stakeholders",
      icon: PersonOutlineIcon,
      onClick: () =>
        onClick(
          `/stakeholders?${queryString.stringify(
            paths.STAKEHOLDERS.defaultQueryParams
          )}`
        ),
    },
    {
      title: "Scrapers",
      icon: HubOutlinedIcon,
      onClick: () => onClick(`/scrapers`),
    },
    {
      title: "Quicksight",
      icon: DashboardIcon,
      onClick: () => onClick(`/quicksight`),
    },
  ];

  if (
    checkGlobalManagementRolePermission(
      userPermissions,
      OBJECT_TYPE.ORGANIZATION,
      OBJECT_NAME.CREATE
    )
  ) {
    options.push({
      title: "Organizations",
      icon: HomeOutlinedIcon,
      onClick: () => onClick("/organizations"),
      disabled: true,
    });
  }
  return options;
};
