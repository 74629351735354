import {
  STAKEHOLDER_ROUTE_ADMIN_PORTAL_REGEX,
  STAKEHOLDER_ROUTE_CLIENT_PORTAL_REGEX,
} from "enums/url";

/**
 * To validate string is valid url.
 * @param {string} value
 */
export function isValidUrl(value) {
  try {
    return Boolean(new URL(value));
  } catch (error) {
    return false;
  }
}

/**
 * To validate the given string is a valid stakeholder route.
 * @param {string} value Stakeholder route.
 * @returns `result` true/false and `params` url params match the stakeholder route.
 */
export function isStakeholderRoute(value) {
  if (typeof value !== "string") {
    return { result: false, params: null };
  }
  const isUrl = isValidUrl(value?.trim());
  if (!isUrl) {
    return { result: false, params: null };
  }
  const isStakeholderRoute =
    STAKEHOLDER_ROUTE_ADMIN_PORTAL_REGEX.exec(value?.trim()) ??
    STAKEHOLDER_ROUTE_CLIENT_PORTAL_REGEX.exec(value?.trim());
  return {
    result: Boolean(isStakeholderRoute),
    params: isStakeholderRoute?.groups,
  };
}
