import React from "react";
import { createBrowserRouter } from "react-router-dom";

import GlobalLayout from "components/GlobalLayout/GlobalLayout";
import Authenticated from "features/authentication/Authenticated/Authenticated";
import PermissionService from "features/authentication/Permission/PermissionService";
import AccessControl from "pages/AccessControl";
import Generation from "pages/Generation";
import GlobalContentSummary from "pages/GlobalContentSummary";
import InsightToolsPage from "pages/InsightTools/InsightToolsPage";
import IssueEditor from "pages/IssueEditor";
import Issues from "pages/Issues";
import LandingPage from "pages/LandingPage";
import Organizations from "pages/Organizations";
import QuickSight from "pages/QuickSight";
import ScraperDetails from "pages/ScraperDetails";
import Scrapers from "pages/Scrapers";
import StakeholderDetails from "pages/StakeholderDetails";
import Stakeholders from "pages/Stakeholders";
import Superprompts from "pages/Superprompts";

export const paths = {
  ROOT: {
    path: "/",
    title: "Home",
  },
  ORGANIZATIONS: {
    path: "organizations",
    title: "Organizations",
  },
  ORGANIZATION: {
    path: ":organizationId",
    title: "Organization",
  },
  ACCESS_CONTROL: {
    path: "rbac",
    title: "Access Control",
  },
  ISSUES: {
    path: "issues",
    title: "Issues Tagging",
    defaultQueryParams: {
      page: 1,
      pageSize: 20,
      sortBy: "issues.name",
      type: "all",
    },
  },
  ADD_ISSUES: {
    path: "new",
    title: "Create",
  },
  EDIT_ISSUES: {
    path: ":issueId",
    title: "Edit",
  },
  GLOBAL_CONTENTS: {
    path: "global-content",
    title: "Global Content",
  },
  GLOBAL_CONTENT: {
    path: ":contentId",
    title: "Global Content Summary",
  },
  STAKEHOLDERS: {
    path: "stakeholders",
    title: "Stakeholders",
    defaultQueryParams: {
      page: 1,
      pageSize: 20,
    },
  },
  STAKEHOLDER_DETAILS: {
    path: ":stakeholderId",
    title: "Details",
  },
  GENERATION: {
    path: "generate",
    title: "Profile Generation",
  },
  SUPERPROMPTS: {
    path: "superprompts",
    title: "Super Prompts",
  },
  SCRAPERS: {
    path: "Scrapers",
    title: "Scrapers",
  },
  SCRAPER_DETAILS: {
    path: ":scraperId",
    title: "Details",
  },
  QUICKSIGHT: {
    path: "QuickSight",
    title: "QuickSight",
  },
  INSIGHT_TOOLS: {
    path: "insight-tools",
    title: "Insight tools",
  },
};

export const router = createBrowserRouter([
  {
    path: paths.ROOT.path,
    element: (
      <Authenticated>
        <GlobalLayout />
        <PermissionService />
      </Authenticated>
    ),
    errorElement: <div />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: paths.ACCESS_CONTROL.path,
        handle: paths.ACCESS_CONTROL,
        children: [
          {
            index: true,
            element: <AccessControl />,
          },
        ],
      },
      {
        path: paths.ISSUES.path,
        handle: paths.ISSUES,
        children: [
          {
            index: true,
            element: <Issues />,
          },
          {
            path: paths.ADD_ISSUES.path,
            element: <IssueEditor />,
            handle: paths.ADD_ISSUES,
          },
          {
            path: paths.EDIT_ISSUES.path,
            element: <IssueEditor />,
            handle: paths.EDIT_ISSUES,
          },
        ],
      },
      {
        path: paths.ORGANIZATIONS.path,
        handle: paths.ORGANIZATIONS,
        children: [
          {
            index: true,
            element: <Organizations />,
          },
          {
            path: paths.ORGANIZATION.path,
            handle: paths.ORGANIZATION,
            children: [
              {
                path: paths.ISSUES.path,
                handle: paths.ISSUES,
                children: [
                  {
                    path: paths.EDIT_ISSUES.path,
                    element: <IssueEditor />,
                    handle: paths.EDIT_ISSUES,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: paths.GLOBAL_CONTENTS.path,
        handle: paths.GLOBAL_CONTENTS,
        children: [
          {
            path: paths.GLOBAL_CONTENT.path,
            element: <GlobalContentSummary />,
            handle: paths.GLOBAL_CONTENT,
          },
        ],
      },
      {
        path: paths.STAKEHOLDERS.path,
        handle: paths.STAKEHOLDERS,
        children: [
          {
            index: true,
            element: <Stakeholders />,
          },
          {
            path: paths.STAKEHOLDER_DETAILS.path,
            element: <StakeholderDetails />,
            handle: paths.STAKEHOLDER_DETAILS,
          },
        ],
      },
      {
        path: paths.GENERATION.path,
        handle: paths.GENERATION,
        children: [
          {
            index: true,
            element: <Generation />,
          },
        ],
      },
      {
        path: paths.SUPERPROMPTS.path,
        handle: paths.SUPERPROMPTS,
        children: [
          {
            index: true,
            element: <Superprompts />,
          },
        ],
      },
      {
        path: paths.SCRAPERS.path,
        handle: paths.SCRAPERS,
        children: [
          {
            index: true,
            element: <Scrapers />,
          },
          {
            path: paths.SCRAPER_DETAILS.path,
            element: <ScraperDetails />,
            handle: paths.SCRAPER_DETAILS,
          },
        ],
      },
      {
        path: paths.QUICKSIGHT.path,
        handle: paths.QUICKSIGHT,
        children: [
          {
            index: true,
            element: <QuickSight />,
          },
        ],
      },
      {
        path: paths.INSIGHT_TOOLS.path,
        handle: paths.INSIGHT_TOOLS,
        children: [
          {
            index: true,
            element: <InsightToolsPage />,
          },
        ],
      },
    ],
  },
]);
