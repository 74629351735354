import { useEffect, useMemo, useState } from "react";
import { GridColDef, GridSortDirection } from "@mui/x-data-grid-pro";
import { Dictionary } from "@reduxjs/toolkit";
import {
  Box,
  Chip,
  DataGridPro,
  Divider,
  SkeletonLoader,
  SomethingWentWrong,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@tsc/component-library/lib/components";
import { keyBy, orderBy } from "lodash";

import { useGetOrganizationsQuery } from "api/organizations";
import {
  Organization,
  useLazyGetWorkspacesQuery,
  Workspace,
} from "api/organizationWorkspaces";
import {
  DashboardSummary,
  useGetDashboardPermissionQuery,
  useGetDashboardsQuery,
} from "api/quicksight";
import SubPage from "components/Layout/SubPage";
import PageHeader from "components/PageHeader/PageHeader";

const EDITOR_PERMISSIONS = ["UpdateDashboard", "DeleteDashboard"];
const ADMIN_PERMISSIONS = ["UpdateDashboardPermissions"];

const parsePrincipal = (
  principal: string | undefined,
  organizations: Dictionary<Organization>,
  workspaces: Dictionary<Workspace>
) => {
  if (principal === undefined) {
    return principal;
  }

  const detail = principal.split("/");
  if (detail.length === 3 && detail[0] === "group") {
    const [, orgName, workspaceName] = detail;
    const organization = organizations[orgName.replace("ORG-", "")];
    const workspace = workspaces[workspaceName.replace("WORKSPACE-", "")];
    const organizationPrincipal = organization
      ? `ORG: ${organization.name} #${organization.id}`
      : orgName;
    const workspacePrincipal = workspace
      ? `${workspace.name} #${workspace.id}`
      : workspaceName;
    return `${organizationPrincipal} / ${workspacePrincipal}`;
  }

  return principal;
};

const AccessCell = ({
  dashboardId,
  organizations,
  workspaces,
}: {
  dashboardId: string;
  organizations: Dictionary<Organization>;
  workspaces: Dictionary<Workspace>;
}) => {
  const {
    data: accessRecord,
    error,
    isFetching,
  } = useGetDashboardPermissionQuery({ dashboardId });

  return (
    <Box key={`access-cell-for-${dashboardId}`} sx={{ py: 1 }}>
      {isFetching && (
        <SkeletonLoader
          loading={isFetching}
          // @ts-ignore
          variant="rounded"
          width={100}
          height="16px"
        />
      )}
      {!isFetching && error && (
        <Typography color="error">
          Unable to load permission of this dashboard.
        </Typography>
      )}
      {!isFetching && !error && (
        <Stack gap={1} direction="row" flexWrap="wrap">
          {accessRecord?.permissions.map((item) => {
            const principal = parsePrincipal(
              item.principal.split(":").pop(),
              organizations,
              workspaces
            );
            const actions = item.actions.map((item) =>
              item.replace("quicksight:", "")
            );
            const hasEditorPermissions = EDITOR_PERMISSIONS.some((item) =>
              actions.includes(item)
            );
            const hasAdminPermisisons = ADMIN_PERMISSIONS.some((item) =>
              actions.includes(item)
            );
            const color: "error" | "warning" | "primary" = hasAdminPermisisons
              ? "error"
              : hasEditorPermissions
              ? "warning"
              : "primary";
            return (
              <Tooltip
                key={`access-cell-${dashboardId}-${principal}`}
                title={
                  <Stack>
                    <Typography fontWeight={"bold"} variant="caption">
                      {principal}
                    </Typography>
                    <Typography variant="caption">
                      {actions.join(", ")}
                    </Typography>
                  </Stack>
                }
              >
                <Chip color={color} label={principal} />
              </Tooltip>
            );
          })}
        </Stack>
      )}
    </Box>
  );
};

const QuickSight = () => {
  const {
    data: { data: dashboards = [] } = {},
    isFetching: isFetchingDashboards,
    isError: isFetchingDashboardError,
  } = useGetDashboardsQuery();

  const {
    data: { data: organizations = [] } = {},
    isFetching: isFetchingOrganizations,
    isError: isFetchingOrganizationsError,
  } = useGetOrganizationsQuery({});

  const isFetching = isFetchingOrganizations || isFetchingDashboards;
  const isError = isFetchingOrganizationsError || isFetchingDashboardError;

  return (
    <Stack gap={2} p={2} sx={{ cursor: "pointer" }}>
      <PageHeader />
      <SubPage>
        {isFetching &&
          (isFetchingOrganizations
            ? "Fetching Organizations..."
            : "Fetching Dashboards...")}
        {!isFetching && isError && <SomethingWentWrong />}
        {!isFetching && !isError && (
          <QuickSightDataContent
            organizations={organizations}
            dashboards={dashboards}
          />
        )}
      </SubPage>
    </Stack>
  );
};

const QuickSightDataContent = ({
  organizations,
  dashboards,
}: {
  organizations: Organization[];
  dashboards: DashboardSummary[];
}) => {
  const [fetchWorkspaces] = useLazyGetWorkspacesQuery();
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);
  const [workspaces, setWorkspaces] = useState<Dictionary<Workspace>>({});
  const [searchText, setSearchText] = useState("");
  const [nameSortDirection, setNameSortDirection] =
    useState<GridSortDirection>("asc");

  useEffect(() => {
    try {
      organizations.forEach(async (org) => {
        const { data: workspaces } = await fetchWorkspaces({
          organizationId: org.id,
        }).unwrap();
        setWorkspaces((prev) => ({
          ...prev,
          ...keyBy(workspaces, "id"),
        }));
      });
    } catch {
      setIsError(true);
    } finally {
      setIsFetching(false);
    }
  }, [organizations, fetchWorkspaces]);

  const organizationsMap = keyBy(organizations as Organization[], "id");

  const columnDefinition: GridColDef<DashboardSummary>[] = useMemo(
    () => [
      {
        headerName: "ID",
        field: "id",
        flex: 1,
        maxWidth: 120,
        sortable: false,
        renderCell: ({ row, value }) => (
          <Tooltip key={`id-field-${row.id}`} title={value}>
            <Box
              sx={{
                textOverflow: "ellipsis",
                maxWidth: "100%",
                maxHeight: "16px",
                overflow: "hidden",
              }}
            >
              {value}
            </Box>
          </Tooltip>
        ),
      },
      {
        headerName: "Name",
        field: "name",
        flex: 1,
        sortable: true,
      },
      {
        headerName: "Access",
        field: "access",
        flex: 3,
        sortable: false,
        renderCell: ({ row }) => (
          <AccessCell
            key={`access-field-${row.id}`}
            dashboardId={row.id}
            organizations={organizationsMap}
            workspaces={workspaces}
          />
        ),
      },
    ],
    [organizationsMap, workspaces]
  );

  const filteredDashboards = useMemo(() => {
    return orderBy(
      dashboards.filter((dashboard) =>
        dashboard.name.toLowerCase().includes(searchText.toLowerCase())
      ),
      ["name"],
      [nameSortDirection ?? "asc"]
    );
  }, [dashboards, searchText, nameSortDirection]);

  return (
    <Stack gap={2}>
      <TextField
        fullWidth
        placeholder="Type your dashboard name here"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <Divider />
      {isFetching && "Fetching workspaces of each organizations..."}
      {!isFetching && isError && <SomethingWentWrong />}
      {!isFetching && !isError && (
        <DataGridPro
          style={{ border: "none" }}
          columns={columnDefinition}
          rows={filteredDashboards}
          rowSelection={false}
          disableColumnMenu
          hideFooter
          getRowHeight={() => "auto"}
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: nameSortDirection }],
            },
          }}
          onSortModelChange={(sortModel) => {
            sortModel.forEach((model) => {
              if (model.field === "name") {
                setNameSortDirection(model.sort);
              }
            });
          }}
          sx={{
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "beige",
            },
          }}
        />
      )}
    </Stack>
  );
};

export default QuickSight;
