import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Editor,
  Grid,
  IconButton,
  LabeledComponent,
  Stack,
  TextField,
} from "@tsc/component-library/lib/components";
import { EDITOR_FORMAT } from "@tsc/component-library/lib/components/Editor/Editor";
import {
  useGeographiesDropdownUtils,
  useInputs,
} from "@tsc/component-library/lib/hooks";
import { STAKEHOLDER_SOURCE } from "enums/stakeholderSource";
import { STAKEHOLDER_TYPE } from "enums/stakeholderType";
import { isEmpty } from "lodash";

import CategoryDropdown from "components/CategoryDropdown/CategoryDropdown";
import CountryDropdown from "components/CountryDropdown/CountryDropdown";
import SubcountryDropdown from "components/SubcountryDropdown/SubcountryDropdown";
import { serverErrorNotification } from "features/notifications/notificationSlice";
import { useGenie } from "hooks/useGenie";
import { getStakeholderAttribute } from "utilities/stakeholder";

const GENERABLE_ATTRIBUTE_TYPE = {
  HEADLINE: "headline",
  SUMMARY: "summary",
};

const DEFAULT_VALUE = {
  name: "",
  headline: "",
  summary: "",
  countryIds: [],
  subcountryIds: [],
  cubeCategoryIds: [],
};

const EDITOR_TOOLBAR_OPTIONS = [
  [{ header: [4, false] }],
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
];

const StakeholderAttributeEditorDialog = ({
  open,
  onClose,
  onSubmit,
  stakeholder,
  value,
  loading: isUpdatingSA,
}) => {
  const [inputs, setInput, setInputs] = useInputs(DEFAULT_VALUE);
  const { optionsMap } = useGeographiesDropdownUtils();
  const dispatch = useDispatch();
  const [generatingType, setGeneratingType] = useState();

  const { isFetching: isGenerating, lastMessage, askSuperprompt } = useGenie();

  const loading = isUpdatingSA || isGenerating;

  const handleClose = () => {
    if (!onClose || loading) {
      return;
    }
    onClose();
  };

  const handleSubmit = () => {
    if (!onSubmit || loading) {
      return;
    }
    onSubmit(inputs);
  };

  const handleGenerateHeadline = async () => {
    try {
      setGeneratingType(GENERABLE_ATTRIBUTE_TYPE.HEADLINE);
      const superpromptId =
        stakeholder?.type === STAKEHOLDER_TYPE.ORGANIZATION
          ? "STAKEHOLDER_PROFILE_GENERATE_ORGANIZATION_HEADLINE"
          : "STAKEHOLDER_PROFILE_GENERATE_PERSON_HEADLINE";
      const context = {
        stakeholder: { ...stakeholder },
      };
      await askSuperprompt(superpromptId, null, context, { noCache: true });
    } catch (error) {
      dispatch(serverErrorNotification());
    } finally {
      setGeneratingType();
    }
  };

  const handleGenerateSummary = async () => {
    try {
      setGeneratingType(GENERABLE_ATTRIBUTE_TYPE.SUMMARY);
      const superpromptId =
        stakeholder?.type === STAKEHOLDER_TYPE.PERSON
          ? "STAKEHOLDER_PROFILE_GENERATE_PERSON_BIO"
          : "STAKEHOLDER_PROFILE_GENERATE_ORGANIZATION_BIO";
      const stakeholderAttributes =
        stakeholder?.stakeholderAttributes.filter(
          (sa) => sa.source !== STAKEHOLDER_SOURCE.GPT
        ) ?? [];
      const name = getStakeholderAttribute({ stakeholderAttributes }, "name");
      const headline = getStakeholderAttribute(
        { stakeholderAttributes },
        "headline"
      );
      const country = stakeholder?.countryIds
        ?.map((countryId) => optionsMap[countryId]?.name ?? countryId)
        .join(", ");
      const context = {
        stakeholder: {
          ...stakeholder,
          name: name,
          headline: headline,
          summary: stakeholderAttributes
            ?.filter((sa) => !!sa.summary)
            .map((sa, index) => `Summary ${index}\n---\n${sa.summary}`)
            .join("\n\n"),
          country: country,
        },
        google_search: {
          query: `${name} - ${headline}` + (country ? ` in ${country}` : ""),
        },
      };
      await askSuperprompt(superpromptId, null, context, { noCache: true });
    } catch (error) {
      dispatch(serverErrorNotification());
    } finally {
      setGeneratingType();
    }
  };

  useEffect(() => {
    if (open) {
      setInputs({ ...DEFAULT_VALUE, ...value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open && lastMessage) {
      switch (generatingType) {
        case GENERABLE_ATTRIBUTE_TYPE.HEADLINE:
          setInputs((prevInputs) => ({
            ...prevInputs,
            headline: lastMessage.replace(/^"|"$/g, ""),
          }));
          break;
        case GENERABLE_ATTRIBUTE_TYPE.SUMMARY:
          setInputs((prevInputs) => ({
            ...prevInputs,
            summary: lastMessage,
          }));
          break;
        default:
          break;
      }
    }
  }, [lastMessage, open, generatingType, setInputs]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Edit Stakeholder Attribute
        <IconButton
          size="small"
          sx={{ position: "absolute", right: 16, top: 16 }}
          onClick={onClose}
          disabled={loading}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          {value?.source !== STAKEHOLDER_SOURCE.GPT && (
            <Grid item xs={12}>
              <LabeledComponent title="Name" vertical removeMargin>
                <TextField
                  autoFocus
                  fullWidth
                  inputProps={{ maxLength: 4096 }}
                  value={inputs.name}
                  onChange={(event) => setInput("name", event.target.value)}
                  disabled={loading || value?.source === STAKEHOLDER_SOURCE.GPT}
                />
              </LabeledComponent>
            </Grid>
          )}
          <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
            <LabeledComponent title="Headline" vertical removeMargin>
              <TextField
                fullWidth
                inputProps={{ maxLength: 256 }}
                value={inputs.headline}
                onChange={(event) => setInput("headline", event.target.value)}
                placeholder={
                  value?.source === STAKEHOLDER_SOURCE.GPT
                    ? "Click on the button below to generate headline."
                    : null
                }
                disabled={loading}
              />
            </LabeledComponent>
            {value?.source === STAKEHOLDER_SOURCE.GPT && (
              <Button
                fullWidth
                variant="outlined"
                disabled={loading}
                onClick={handleGenerateHeadline}
                startIcon={
                  isGenerating &&
                  generatingType === GENERABLE_ATTRIBUTE_TYPE.HEADLINE ? (
                    <CircularProgress size={14} color="inherit" />
                  ) : null
                }
              >
                {!loading && inputs.headline ? "Re-" : ""}Generate Headline
              </Button>
            )}
          </Grid>

          {value?.source !== STAKEHOLDER_SOURCE.GPT && (
            <Grid item xs={6}>
              <Stack direction="row" gap={2}>
                <LabeledComponent
                  title="Categories"
                  vertical
                  removeMargin
                  fullWidth
                >
                  <CategoryDropdown
                    multiple
                    value={inputs.cubeCategoryIds}
                    onChange={(value) => setInput("cubeCategoryIds", value)}
                    disabled={loading}
                  />
                </LabeledComponent>
              </Stack>
            </Grid>
          )}

          {value?.source !== STAKEHOLDER_SOURCE.GPT && (
            <Grid item xs={6}></Grid>
          )}

          {value?.source !== STAKEHOLDER_SOURCE.GPT && (
            <Grid item xs={6}>
              <LabeledComponent title="Country" vertical removeMargin>
                <CountryDropdown
                  value={inputs.countryIds?.[0] ?? null}
                  onChange={(value) => {
                    if (inputs.countryIds?.[0] !== value) {
                      setInput("subcountryIds", []);
                    }
                    setInput("countryIds", value ? [value] : []);
                  }}
                  disabled={loading}
                />
              </LabeledComponent>
            </Grid>
          )}

          {value?.source !== STAKEHOLDER_SOURCE.GPT && (
            <Grid item xs={6}>
              <LabeledComponent title="States" vertical removeMargin>
                <SubcountryDropdown
                  multiple
                  value={inputs.subcountryIds}
                  onChange={(value) => setInput("subcountryIds", value)}
                  disabled={loading || isEmpty(inputs.countryIds)}
                  filterOptions={(options, state, dictionary) => {
                    return options?.filter(
                      (option) =>
                        dictionary[option].countryId === inputs.countryIds[0]
                    );
                  }}
                />
              </LabeledComponent>
            </Grid>
          )}

          <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
            <LabeledComponent title="Summary" vertical removeMargin>
              <Editor
                isUncontrolled
                readOnly={loading}
                minHeight={200}
                format={EDITOR_FORMAT.MARKDOWN}
                value={inputs.summary}
                defaultValue={inputs.summary}
                onBlur={(value) => setInput("summary", value)}
                toolbarOptions={EDITOR_TOOLBAR_OPTIONS}
                placeholder={
                  value?.source === STAKEHOLDER_SOURCE.GPT
                    ? "Click on the button below to generate summary."
                    : null
                }
              />
            </LabeledComponent>

            {value?.source === STAKEHOLDER_SOURCE.GPT && (
              <Button
                fullWidth
                variant="outlined"
                disabled={loading}
                onClick={handleGenerateSummary}
                startIcon={
                  isGenerating &&
                  generatingType === GENERABLE_ATTRIBUTE_TYPE.SUMMARY ? (
                    <CircularProgress size={14} color="inherit" />
                  ) : null
                }
              >
                {!loading && inputs.summary ? "Re-" : ""}Generate Summary
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={
            isUpdatingSA ? <CircularProgress size={14} color="inherit" /> : null
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StakeholderAttributeEditorDialog;
