import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  SkeletonLoader,
  useMediaQuery,
  useTheme,
} from "@tsc/component-library/lib/components";

import StakeholderCardItem from "features/stakeholders/StakeholderCardItem/StakeholderCardItem";
import StakeholderClassChip from "features/stakeholders/StakeholderClassChip/StakeholderClassChip";
import StakeholderSourceAvatarGroup from "features/stakeholders/StakeholderSourceAvatarGroup/StakeholderSourceAvatarGroup";
import StakeholderVisibilityChip from "features/stakeholders/StakeholderVisibilityChip/StakeholderVisibilityChip";

const StakeholderTable = ({ value, isFetching, ...otherProps }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const data = isFetching
    ? [...Array(20)].map((_, i) => ({ id: `skeleton_${i}`, isFetching: true }))
    : value;
  return (
    <DataGridPro
      columns={columnDefinition}
      rows={data}
      data-testid="stakeholderListTable"
      slots={{ loadingOverlay: Box }}
      columnVisibilityModel={{
        class: isLargeScreen,
        subscriptionStatus: isLargeScreen || isMediumScreen,
        sources: isLargeScreen || isMediumScreen,
      }}
      sx={{
        "& .MuiDataGrid-columnHeader": {
          display: isFetching ? "none" : "block",
        },
      }}
      {...otherProps}
    />
  );
};

export default StakeholderTable;

const columnDefinition = [
  {
    headerName: "Name",
    field: "stakeholders.name",
    renderCell: ({ row }) => (
      <StakeholderCardItem
        stakeholder={row}
        isFetching={row.isFetching}
        avatarSize="medium"
        showLink
      />
    ),
    sortable: false,
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Profile Type",
    field: "class",
    renderCell: ({ row }) => (
      <SkeletonLoader
        loading={row.isFetching}
        variant="rounded"
        width={row.id % 2 === 1 ? "80px" : "90px"}
      >
        <StakeholderClassChip stakeholder={row} />
      </SkeletonLoader>
    ),
    sortable: false,
    minWidth: 170,
  },
  {
    headerName: "Sources",
    field: "stakeholderAttributes.source",
    renderCell: ({ row }) => {
      return (
        <SkeletonLoader
          loading={row.isFetching}
          variant="rounded"
          width={row.id % 2 === 0 ? "100px" : "120px"}
          height="32px" // match the size of skeleton loaders for Chips that have a default height of '32px'
        >
          {!row.isFetching && (
            <StakeholderSourceAvatarGroup stakeholder={row} />
          )}
        </SkeletonLoader>
      );
    },
    sortable: false,
    minWidth: 200,
    flex: 0.2,
  },
  {
    headerName: "Visibility",
    field: "stakeholder.visibility",
    renderCell: ({ row }) => {
      return (
        <SkeletonLoader
          loading={row.isFetching}
          variant="rounded"
          width={row.id % 2 === 0 ? "100px" : "120px"}
          height="32px" // match the size of skeleton loaders for Chips that have a default height of '32px'
        >
          {!row.isFetching && <StakeholderVisibilityChip stakeholder={row} />}
        </SkeletonLoader>
      );
    },
    sortable: false,
    minWidth: 150,
  },
];
