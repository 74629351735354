export const ROLE_OBJECT_TYPE = {
  ORGANIZATION: "organization",
  PROJECT: "project",
  TOUCHPOINT: "touchpoint",
  PLAN: "engagement_plan",
  STAKEHOLDER: "stakeholder",
  CHAT_COMPLETION: "chatCompletion",
  ORGANIZATION_ISSUE: "organizationIssue",
  MANAGEMENT: "management",
};
