import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  Divider,
  EmptyContent,
  Grid,
  MaterialSymbol,
  Paper,
  SomethingWentWrong,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import { SYMBOL_SIZES } from "@tsc/component-library/lib/components/MaterialSymbol/MaterialSymbol";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { JsonData, JsonEditor as Editor } from "json-edit-react";

import { db } from "configurations/firebase";
import { serverErrorNotification } from "features/notifications/notificationSlice";

type FeatureFlagsType = Record<string, boolean>;

type InsightFeatureFlagsData = {
  organizations?: Record<string, FeatureFlagsType>;
  workspaces?: Record<string, FeatureFlagsType>;
} & Record<string, FeatureFlagsType>;

const InsightFeatureFlags = () => {
  const dispatch = useDispatch();
  const [featureFlags, setFeatureFlags] = useState<InsightFeatureFlagsData>();
  const [isFetching, setIsFetching] = useState(true);
  const [fetchingError, setFetchingError] = useState(false);
  const [refetch, setRefetch] = useState(true);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        setFeatureFlags(
          (
            await getDoc<InsightFeatureFlagsData>(
              doc(db, "master_data/insight_feature_flags")
            )
          ).data()
        );
      } catch (error) {
        // @ts-ignore
        // eslint-disable-next-line no-console
        console.error(error);
        // @ts-ignore
        dispatch(serverErrorNotification());
        setFetchingError(true);
      } finally {
        setIsFetching(false);
      }
    };

    if (refetch) {
      setRefetch(false);
      fetchFeatureFlags();
    }
  }, [dispatch, refetch]);

  if (isFetching) {
    return <Box>Fetching data</Box>;
  }

  return (
    <Paper>
      <Stack gap={2} p={2}>
        {isFetching && "Fetching Feature Flags..."}
        {!isFetching && fetchingError && <SomethingWentWrong />}
        {!isFetching && !fetchingError && featureFlags === undefined && (
          <EmptyContent />
        )}
        {!isFetching && !fetchingError && featureFlags !== undefined && (
          <FeatureFlagsContent
            featureFlags={featureFlags}
            refetch={() => setRefetch(true)}
          />
        )}
      </Stack>
    </Paper>
  );
};

const validateData = (data: JsonData) => {
  if (typeof data !== "object") {
    return ["Root data must be an object"];
  }

  const errors: string[] = [];
  for (const [rootKey, rootValue] of Object.entries(data)) {
    if (rootKey === "organizations") {
      for (const [orgId, orgFeatureFlags] of Object.entries(rootValue)) {
        if (typeof orgFeatureFlags !== "object" || orgFeatureFlags === null) {
          errors.push(
            `Invalid value #.organizations.${orgId} -> Got ${typeof orgFeatureFlags}. Expecting a feature flags object`
          );
          break;
        }

        for (const [orgFlagKey, orgFlagValue] of Object.entries(
          orgFeatureFlags
        )) {
          if (typeof orgFlagKey !== "string") {
            errors.push(
              `Invalid key #.organizations.${orgId}.${orgFlagKey} Expecting a string. Got ${typeof orgFlagKey}`
            );
          }

          if (typeof orgFlagValue !== "boolean") {
            errors.push(
              `Invalid value #.organizations.${orgId}.${orgFlagKey} -> Got ${typeof orgFlagValue}. Expecting a boolean`
            );
          }
        }
      }
      continue;
    }

    if (rootKey === "workspaces") {
      for (const [workspaceId, workspaceFeatureFlags] of Object.entries(
        rootValue
      )) {
        if (
          typeof workspaceFeatureFlags !== "object" ||
          workspaceFeatureFlags === null
        ) {
          errors.push(
            `Invalid value #.workspaces.${workspaceId} -> Got ${typeof workspaceFeatureFlags} Expecting a feature flags object`
          );
          break;
        }

        for (const [workspaceFlagKey, workspaceFlagValue] of Object.entries(
          workspaceFeatureFlags
        )) {
          if (typeof workspaceFlagKey !== "string") {
            errors.push(
              `Invalid key #.workspaces.${workspaceId}.${workspaceFlagKey} Expecting a string. Got ${typeof workspaceFlagKey}`
            );
          }

          if (typeof workspaceFlagValue !== "boolean") {
            errors.push(
              `Invalid value #.workspaces.${workspaceId}.${workspaceFlagKey} -> Got ${typeof workspaceFlagValue}. Expecting a boolean`
            );
          }
        }
      }
      continue;
    }

    if (typeof rootValue !== "boolean") {
      errors.push(
        `feature flag #.${rootKey} must be a boolean. Got ${typeof rootValue}`
      );
    }
  }

  return errors;
};

const FeatureFlagsContent = ({
  featureFlags,
  refetch,
}: {
  featureFlags: InsightFeatureFlagsData;
  refetch: () => void;
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<string[]>(validateData(featureFlags));
  const [newData, setNewData] = useState<JsonData>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (newData !== undefined) {
      setErrors(validateData(newData));
    }
  }, [newData]);

  const handleSave = async (newData: object) => {
    try {
      setIsSaving(true);
      await setDoc(doc(db, "master_data/insight_feature_flags"), newData);
    } catch (error) {
      // @ts-ignore
      // eslint-disable-next-line no-console
      console.error(error);
      // @ts-ignore
      dispatch(serverErrorNotification());
    } finally {
      setIsSaving(false);
      refetch();
    }
  };

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2} display="flex" justifyContent="flex-end">
        <Button
          color="secondary"
          onClick={() => {
            setNewData(undefined);
            setErrors(validateData(featureFlags));
          }}
        >
          <Stack direction="row" gap={1} alignItems={"center"}>
            <MaterialSymbol symbol="restart_alt" size={SYMBOL_SIZES.XSMALL} />
            Reset
          </Stack>
        </Button>
        <Button
          disabled={errors.length > 0 || newData === undefined || isSaving}
          variant="contained"
          onClick={() => handleSave(newData as object)}
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </Stack>
      <Grid container spacing={2}>
        <Grid item sm={12} md={8}>
          <Card>
            <Editor
              minWidth="100%"
              data={newData ?? featureFlags}
              onUpdate={(data) => {
                setNewData(data.newData);
              }}
              keySort={([a], [b]) => {
                if (a === "organizations" || a === "workspaces") return 2;
                if (b === "organizations" || b === "workspaces") return -2;
                if (typeof a === "string" && typeof b === "string")
                  a.localeCompare(b);
                if (typeof a === "number" && typeof b === "number")
                  return a > b ? 1 : -1;

                return 0;
              }}
            />
          </Card>
        </Grid>
        <Grid item sm={12} md={4}>
          <Card>
            <Stack height={"100%"} p={2} gap={2}>
              <Typography variant="h6">Errors</Typography>
              <Stack gap={1}>
                {errors.map((error) => (
                  <>
                    <Typography variant="body2" color="error">
                      {error}
                    </Typography>
                    <Divider />
                  </>
                ))}
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default InsightFeatureFlags;
