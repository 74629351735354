import { ROLE_OBJECT_TYPE } from "enums/roleObjectType";

export const STRINGIFY_OPTIONS = {
  arrayFormat: "indices",
  skipNulls: true,
};

const DOMAIN_NAME_REGEX =
  /^(?!www\.)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;

export const STAKEHOLDER_ROUTE_ADMIN_PORTAL_REGEX =
  /^(https?:\/\/)(localhost:3001|admin\.genie(\.dev|\.uat)?\.tsc\.ai)\/stakeholders\/(?<stakeholderId>[0-9]+)$/;
export const STAKEHOLDER_ROUTE_CLIENT_PORTAL_REGEX =
  /^(https?:\/\/)(localhost:3000|genie(\.dev|\.uat)?\.tsc\.ai)\/organizations\/(?<organizationId>[0-9]+)\/workspaces\/(?<workspaceId>[0-9]+)\/stakeholders\/(?<stakeholderId>[0-9]+)$/;

/**
 * To validate string is valid url.
 * @param {string} value
 */
export function isValidUrl(value) {
  try {
    return Boolean(new URL(value));
  } catch (error) {
    return false;
  }
}

export function isValidDomain(value) {
  return DOMAIN_NAME_REGEX.test(value);
}

const ROUTE_MAP = {
  [ROLE_OBJECT_TYPE.TOUCHPOINT]: "touchpoints",
  [ROLE_OBJECT_TYPE.STAKEHOLDER]: "stakeholders",
  [ROLE_OBJECT_TYPE.PLAN]: "plans",
};

export function getRoute({ organizationId, projectId, objectType, objectId }) {
  let route = `/organizations/${organizationId}/workspaces/${projectId}`;

  if (objectType) {
    route += `/${ROUTE_MAP[objectType]}`;
  }

  if (objectId) {
    route += `/${objectId}`;
  }

  return route;
}
