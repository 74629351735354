import api from "api";

const API_ROUTE = "insights/api/v1/quicksight";

export type DashboardSummary = {
  id: string;
  name: string;
  createdTime: string;
  lastUpdatedTime: string;
  publishedVersionNumber: number;
  lastPublishedTime: string;
};

export type DashboardPermissionRecord = {
  dashboardId: string;
  dashboardArn: string;
  permissions: {
    principal: string;
    actions: string[];
  }[];
};

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDashboards: build.query<
      {
        data: DashboardSummary[];
      },
      void
    >({
      query: () => ({
        url: `${API_ROUTE}/dashboards`,
      }),
    }),
    getDashboardPermission: build.query<
      DashboardPermissionRecord,
      { dashboardId: string }
    >({
      query: ({ dashboardId }) => ({
        url: `${API_ROUTE}/dashboards/${dashboardId}/access`,
      }),
    }),
  }),
});

export const { useGetDashboardsQuery, useGetDashboardPermissionQuery } =
  extendedApi;
