import React from "react";
import { useSelector } from "react-redux";
import { components } from "@tsc/component-library/lib/commonTheme";
import { Autocomplete, TextField } from "@tsc/component-library/lib/components";
import lodash, { isEmpty } from "lodash";

import { selectCategories } from "features/classification/classificationSlice";

const CategoryDropdown = ({
  label,
  value,
  placeholder,
  disabled,
  disableClearable,
  fullWidth,
  multiple,
  onChange,
}) => {
  const categories = useSelector(selectCategories);
  const options = lodash
    .chain(categories)
    .keys()
    .sortBy((id) => categories[id]?.name)
    .value();
  return (
    <Autocomplete
      size="small"
      disableCloseOnSelect
      fullWidth={fullWidth}
      multiple={multiple}
      disabled={disabled}
      disableClearable={disableClearable}
      value={value}
      options={options}
      getOptionLabel={(option) => categories[option]?.name}
      onChange={(_, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...components.MuiTextField.defaultProps.InputLabelProps,
          }}
          label={label}
          placeholder={isEmpty(value) ? placeholder : null}
        />
      )}
    />
  );
};

export default CategoryDropdown;
