import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useMatches } from "react-router-dom/dist";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@tsc/component-library/lib/components";

/**
 * @param {{
 *  children?: import("react").ReactNode;
 *  title?: string;
 * }} props
 * @returns
 */
const PageHeader = ({ children, title }) => {
  const navigate = useNavigate();
  const matches = useMatches();
  let traversablePaths = [...matches];

  // We exclude the root from the breadcrumb
  traversablePaths.shift();

  // Routes with { index: true } appear a second time in the paths. We want to remove that
  traversablePaths = traversablePaths.filter(
    (path) => !path.pathname.match(/^\/.+\/$/)
  );

  let labels = traversablePaths.map(({ pathname, handle }) => {
    const query = handle?.defaultQueryParams || "";
    return { label: handle?.title, href: `${pathname}?${query}` };
  });

  const lastLabel = labels[labels.length - 1]?.label;

  return (
    <Paper elevation={4}>
      <Box p={1}>
        <Box m={1}>
          <Breadcrumbs aria-label="breadcrumb">
            {labels.slice(0, -1).map(({ label, href }) => {
              return (
                <Link
                  key={href}
                  color="inherit"
                  underline="hover"
                  component={RouterLink}
                  to={href}
                >
                  {label}
                </Link>
              );
            })}
            <Typography color="text.primary">{lastLabel}</Typography>
          </Breadcrumbs>
        </Box>
        <Box m={1}>
          <Typography
            variant="h5"
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <IconButton
              size="medium"
              onClick={() => navigate(-1)}
              sx={{ ml: -1 }}
            >
              <ArrowBackIosNewIcon fontSize="inherit" />
            </IconButton>
            {title ?? lastLabel}
          </Typography>
        </Box>
      </Box>
      {children}
    </Paper>
  );
};

export default PageHeader;
