import React from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
} from "@tsc/component-library/lib/components";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import queryString from "query-string";

import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import { paths } from "configurations/router";
import { selectPermissions } from "features/authentication/authenticationSlice";
import { checkGlobalManagementRolePermission } from "utilities/data";

const LandingPage = () => {
  const userPermissions = useSelector(selectPermissions);
  const canViewOrgs = checkGlobalManagementRolePermission(
    userPermissions,
    OBJECT_TYPE.ORGANIZATION,
    OBJECT_NAME.CREATE
  );
  const canViewSuperpromt = checkGlobalManagementRolePermission(
    userPermissions,
    OBJECT_TYPE.SUPERPROMPTS,
    OBJECT_NAME.READ
  );
  const hasManagementReadRole = checkGlobalManagementRolePermission(
    userPermissions,
    OBJECT_TYPE.MANAGEMENT,
    OBJECT_NAME.READ
  );
  return (
    <Page hidePageHeader={true}>
      <SubPage empty={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Button
              sx={{ textTransform: "none" }}
              component={RouterLink}
              to="/organizations"
              disabled={!canViewOrgs}
            >
              <Typography variant="h6">Organizations</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              sx={{ textTransform: "none" }}
              component={RouterLink}
              to={`/issues?${queryString.stringify(
                paths.ISSUES.defaultQueryParams
              )}`}
            >
              <Typography variant="h6">Issues</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              sx={{ textTransform: "none" }}
              component={RouterLink}
              to="/rbac"
            >
              <Typography variant="h6">Access Control</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              sx={{ textTransform: "none" }}
              component={RouterLink}
              to={`/stakeholders?${queryString.stringify(
                paths.STAKEHOLDERS.defaultQueryParams
              )}`}
            >
              <Typography variant="h6">Stakeholders</Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              sx={{ textTransform: "none" }}
              component={RouterLink}
              to={`/generate`}
            >
              <Typography variant="h6">Profile Generation</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Button
              sx={{ textTransform: "none" }}
              component={RouterLink}
              to={`/superprompts`}
              disabled={!canViewSuperpromt}
            >
              <Typography variant="h6">Superprompts</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Button
              sx={{ textTransform: "none" }}
              component={RouterLink}
              to={`/scrapers`}
              disabled={!canViewSuperpromt}
            >
              <Typography variant="h6">Scrapers</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Button
              sx={{ textTransform: "none" }}
              component={RouterLink}
              to={`/quicksight`}
              disabled={!hasManagementReadRole}
            >
              <Typography variant="h6">QuickSight</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Button
              sx={{ textTransform: "none" }}
              component={RouterLink}
              to={`/insight-tools`}
              disabled={!hasManagementReadRole}
            >
              <Typography variant="h6">Insight tools</Typography>
            </Button>
          </Grid>
        </Grid>
      </SubPage>
    </Page>
  );
};

export default LandingPage;
