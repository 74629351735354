import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import { useSearchParams } from "@tsc/component-library/lib/hooks";

import SubPage from "components/Layout/SubPage";
import PageHeader from "components/PageHeader/PageHeader";
import InsightFeatureFlags from "pages/InsightTools/InsightFeatureFlags";

import AtiumSPMMigrationTool from "./AtiumSPMMigration";

const OPTIONS = ["feature-flags", "atium-spm-migration"] as const;
type OptionType = (typeof OPTIONS)[number];
const INITIAL_OPTION: OptionType = "feature-flags";

type OptionConfig = {
  label: string;
  component: React.ReactElement;
};

const OPTIONS_CONFIG: Record<OptionType, OptionConfig> = {
  "feature-flags": {
    label: "Feature Flags",
    component: <InsightFeatureFlags />,
  },
  "atium-spm-migration": {
    label: "Atium SPM Migration",
    component: <AtiumSPMMigrationTool />,
  },
};

const InsightToolsPage = () => {
  const [searchParams, , setSearchParams] = useSearchParams({});
  const params = searchParams as { [key: string]: any };
  const option =
    "tool" in params ? (params.tool as OptionType) : INITIAL_OPTION;
  const setOption = (option: OptionType) => {
    // @ts-ignore
    setSearchParams({ ...searchParams, tool: option });
  };

  return (
    <Stack gap={2} p={2}>
      <PageHeader />
      <SubPage>
        <Box display="flex" width="100%" justifyContent="center">
          <Stack gap={2} maxWidth={1280} width="100%">
            <Stack gap={2} direction="row" alignItems="center">
              <Typography variant="h6">Tools</Typography>
              <Select
                value={option}
                onChange={(e) => setOption(e.target.value as OptionType)}
                sx={{ minWidth: 300 }}
              >
                {OPTIONS.map((opt) => (
                  <MenuItem key={opt} value={opt}>
                    {OPTIONS_CONFIG[opt].label}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            {OPTIONS_CONFIG[option].component}
          </Stack>
        </Box>
      </SubPage>
    </Stack>
  );
};

export default InsightToolsPage;
