import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { json } from "@codemirror/lang-json";
import {
  Alert,
  Box,
  Button,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import CodeMirror from "@uiw/react-codemirror";
import { doc, setDoc } from "firebase/firestore";
import { JsonData } from "json-edit-react";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";

import { db } from "configurations/firebase";
import { serverErrorNotification } from "features/notifications/notificationSlice";

import { INetworkGraphData, NodeType } from "./networks/types";

export function generate32CharUUID(): string {
  const uuid = uuidv4().replace(/-/g, "");
  return uuid;
}

type StepConfig = {
  key: string;
  label: string;
};

const STEPS: StepConfig[] = [
  {
    key: "insert-spm-source",
    label: "Insert ATIUM SPM Source",
  },
  {
    key: "converting",
    label: "Convert to Genie Network",
  },
  {
    key: "save-to-network",
    label: "Save to a Network",
  },
];

const ColorTypeSchema = z.union([
  z.string(),
  z.object({
    r: z.number(),
    g: z.number(),
    b: z.number(),
    a: z.number(),
  }),
]);

const AtiumSPMLabelSchema = z.object({
  id: z.string(),
  color: ColorTypeSchema,
  size: z.number(),
  text: z.string(),
  x: z.number(),
  y: z.number(),
});

const AtiumSPMLinkSchema = z.object({
  is_removed: z.boolean(),
  is_reversed: z.boolean(),
  relationship_id: z.string(),
  source_id: z.string(),
  target_id: z.string(),
});

const AtiumSPMConnectionSchema = z.object({
  relationship_id: z.string(),
  to_label: z.string(),
});

const AtiumSPMNodeSchema = z.object({
  id: z.string(),
  domainId: z.string().optional(),
  name: z.string(),
  base_type: z.string(),
  image_url: z.string().optional(),
  x: z.number(),
  y: z.number(),
  hide: z.boolean(),
  connections: z.array(AtiumSPMConnectionSchema).optional(),
});

const AtiumGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  color: ColorTypeSchema,
  nodes: z.array(
    z.object({
      id: z.string(),
    })
  ),
});

const AtiumSPMTypeSchema = z.object({
  data: z.object({
    labels: z.array(AtiumSPMLabelSchema).optional(),
    links: z.array(AtiumSPMLinkSchema).optional(),
    nodes: z.array(AtiumSPMNodeSchema).optional(),
    transform: z
      .object({
        k: z.number(),
        x: z.number(),
        y: z.number(),
      })
      .optional(),
    groups: z.array(AtiumGroupSchema).optional(),
  }),
});

type AtiumSPMType = z.infer<typeof AtiumSPMTypeSchema>;
type AtumColorType = z.infer<typeof ColorTypeSchema>;

const ATIUM_NODE_TYPE_TO_GENIE_NODE_TYPE: { [key: string]: NodeType } = {
  organization: NodeType.Organization,
  person: NodeType.Person,
  media_item: NodeType.Source,
};

const processSPMNodesToNetorkNodes = (
  atiumSPM: AtiumSPMType,
  scaleFactor: number,
  offsetX: number,
  offsetY: number
) => {
  const nodes: INetworkGraphData["nodes"] = {};

  for (const node of atiumSPM.data.nodes ?? []) {
    let nodeType = ATIUM_NODE_TYPE_TO_GENIE_NODE_TYPE[node.base_type];
    if (nodeType === undefined || node.hide) {
      continue;
    }

    const domainId = node.domainId;
    if (domainId === undefined) {
      if (nodeType === NodeType.Organization) {
        nodeType = NodeType.CustomOrganization;
      }

      if (nodeType === NodeType.Person) {
        nodeType = NodeType.CustomPerson;
      }
    }

    nodes[node.id] = {
      id: node.id,
      domainLabel: node.name,
      labelWidth: 160,
      type: nodeType,
      size: 48,
      imageUrl: node.image_url,
      position: [
        (node.x + offsetX) * scaleFactor,
        (node.y + offsetY) * scaleFactor,
      ],
      ...(domainId ? { domainId } : {}),
    };
  }

  return nodes;
};

const atiumColorToGenieColor = (color: AtumColorType): string => {
  if (typeof color === "string") {
    return color;
  }

  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
};

const estimateTextAreaWidth = (text: string, fontSize: number) => {
  const lines = text.split("\n");
  const longestLine = lines.reduce(
    (max, line) => Math.max(max, line.length),
    0
  );
  return longestLine * fontSize * 0.5; // Approximation factor for character width
};

const estimateTextAreaHeight = (text: string, fontSize: number) => {
  // Approximation factor for character height
  return text.split("\n").length * fontSize * 1.2;
};

const processSPMLabelsToNetorkTextNodes = (
  atiumSPM: AtiumSPMType,
  scaleFactor: number,
  offsetX: number,
  offsetY: number
) => {
  const uiComps: INetworkGraphData["uiComponents"] = {};

  for (const label of atiumSPM.data.labels ?? []) {
    uiComps[label.id] = {
      type: "Text",
      id: label.id,
      domainLabel: label.text,
      width: estimateTextAreaWidth(label.text, label.size),
      height: estimateTextAreaHeight(label.text, label.size),
      position: [
        (label.x + offsetX) * scaleFactor,
        (label.y + offsetY) * scaleFactor,
      ],
      textColor: atiumColorToGenieColor(label.color),
      fontSize: label.size,
    };
  }

  return uiComps;
};

const processSPMLinksToNetorkLinks = (atiumSPM: AtiumSPMType) => {
  const links: INetworkGraphData["links"] = {};

  for (const link of atiumSPM.data.links ?? []) {
    if (link.is_removed) {
      continue;
    }

    const primaryNode = atiumSPM.data.nodes?.find(
      (node) => node.id === (link.is_reversed ? link.target_id : link.source_id)
    );
    const connection = primaryNode?.connections?.find(
      (conn) => conn.relationship_id === link.relationship_id
    );

    links[link.relationship_id] = {
      id: link.relationship_id,
      source: link.is_reversed ? link.target_id : link.source_id,
      target: link.is_reversed ? link.source_id : link.target_id,
      bezierControlPoints: "none",
      label: connection ? connection.to_label : "",
    };
  }

  return links;
};

const processSPMGroupToNetorkGroup = (atiumSPM: AtiumSPMType) => {
  const groups: INetworkGraphData["groups"] = {};

  for (const group of atiumSPM.data.groups ?? []) {
    groups[group.id] = {
      id: group.id,
      label: group.name,
      color: atiumColorToGenieColor(group.color),
      nodesIds: group.nodes.reduce((acc, node) => {
        acc[node.id] = true;
        return acc;
      }, {} as { [nodeId: string]: true }),
    };
  }

  return groups;
};

const processAtiumSPMToGenieNetwork = (
  atiumSPM: AtiumSPMType
): INetworkGraphData => {
  const scaleFactor = 1 + (1 - (atiumSPM.data.transform?.k ?? 1));
  const offsetX = atiumSPM.data.transform?.x ?? 0 - 1920 / 2;
  const offsetY = atiumSPM.data.transform?.y ?? 0 - 1080 / 2;

  return {
    nodes: processSPMNodesToNetorkNodes(
      atiumSPM,
      scaleFactor,
      offsetX,
      offsetY
    ),
    links: processSPMLinksToNetorkLinks(atiumSPM),
    uiComponents: processSPMLabelsToNetorkTextNodes(
      atiumSPM,
      scaleFactor,
      offsetX,
      offsetY
    ),
    groups: processSPMGroupToNetorkGroup(atiumSPM),
  };
};

const AtiumSPMMigrationTool = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [atiumSPMContent, setAtiumSPMContent] = useState<string>("");
  const [atiumSPMJsonObject, setAtiumSPMJsonObject] = useState<AtiumSPMType>();
  const [genieNetworkObject, setGenieNetworkObject] = useState<object>();
  const [isProcessingNetwork, setIsProcessingNetwork] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [organizationId, setOrganizationId] = useState<string>("");
  const [networkId, setNetworkId] = useState<string>("");
  const [isValidToSubmit, setIsValidToSubmit] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const dispatch = useDispatch();

  // We use this effect to parse and validate the steps state before moving to the next state
  useEffect(() => {
    setError(null);
    setIsValidToSubmit(false);

    //----------------------------------------------
    // Step one: Gather and validate Atium SPM Source
    if (atiumSPMContent === "") {
      setAtiumSPMJsonObject(undefined);
      setError("Please insert your SPM Source");
      return;
    }

    try {
      const jsonContent: JsonData = JSON.parse(atiumSPMContent);
      setAtiumSPMJsonObject(AtiumSPMTypeSchema.parse(jsonContent));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (error instanceof z.ZodError) {
        setError("Json data have invalid ATIUM SPM format");
        return;
      }

      setError("Unable to parse JSON content from your SPM Source");
      return;
    }

    if (organizationId === "") {
      setError("Please enter your organization id");
      return;
    }

    if (!/^\d+$/.test(organizationId)) {
      setError("Invalid organization id, expecting a number");
      return;
    }

    const orgId = parseInt(organizationId, 10);
    if (orgId < 0) {
      setError("Invalid organization id, expecting a positive number");
    }

    if (currentStep < 1) {
      return;
    }

    //----------------------------------------------
    // Step two: Convert to Genie Network
    if (atiumSPMJsonObject === undefined) {
      setError(
        "Invalid SPM Source, expecting a valid json object please go back to previous step!"
      );
      return;
    }

    if (genieNetworkObject === undefined) {
      setIsProcessingNetwork(true);
      return;
    }

    if (currentStep < 2) {
      return;
    }

    //----------------------------------------------
    // Step three: Write to firestore
    if (genieNetworkObject === undefined) {
      setError(
        "Converted Genie Network is empty, please go back to previous step!"
      );
      return;
    }

    if (networkId === "") {
      setError("Please enter your network id");
      return;
    }

    if (!/^\d+$/.test(networkId)) {
      setError("Invalid network id, expecting a number");
      return;
    }

    const netDocId = parseInt(networkId, 10);
    if (netDocId < 0) {
      setError("Invalid network id, expecting a positive number");
    }

    setIsValidToSubmit(true);
  }, [
    atiumSPMContent,
    atiumSPMJsonObject,
    currentStep,
    genieNetworkObject,
    networkId,
    organizationId,
  ]);

  useEffect(() => {
    if (isProcessingNetwork && atiumSPMJsonObject !== undefined) {
      try {
        const networkData = processAtiumSPMToGenieNetwork(atiumSPMJsonObject);
        setGenieNetworkObject(networkData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError("Failed to process SPM Source to Genie Network");
        setIsProcessingNetwork(false);
        return;
      }
    }

    setIsProcessingNetwork(false);
  }, [atiumSPMJsonObject, isProcessingNetwork]);

  useEffect(() => {
    if (setAtiumSPMJsonObject === undefined) {
      return;
    }
  }, [setAtiumSPMJsonObject]);

  const handleSubmit = async () => {
    try {
      setIsSaving(true);
      await setDoc(
        doc(db, `organizations/${organizationId}/networks/${networkId}`),
        { data: genieNetworkObject },
        { merge: true }
      );
    } catch (error) {
      // @ts-ignore
      // eslint-disable-next-line no-console
      console.error(error);
      // @ts-ignore
      dispatch(serverErrorNotification());
      setError("Failed to save Genie Network to Firestore");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Paper>
      <Stack gap={2} p={2}>
        <Typography variant="h6">Atium SPM Migration</Typography>
        <Divider />
        <Box p={2}>
          <Stepper activeStep={currentStep}>
            {STEPS.map((stepConfig, stepIndex) => {
              return (
                <Step key={stepConfig.key} completed={currentStep > stepIndex}>
                  <StepLabel>{stepConfig.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {error && <Alert severity="error">{error}</Alert>}
        {currentStep === 0 && (
          <Box sx={{ width: "100%" }}>
            <Stack gap={2}>
              <Stack direction="row" gap={2}>
                <TextField
                  value={organizationId}
                  onChange={(e) => setOrganizationId(e.target.value.trim())}
                  label="Organization ID"
                />
              </Stack>
              <CodeMirror
                value={atiumSPMContent}
                height="400px"
                extensions={[json()]}
                onChange={(value) => setAtiumSPMContent(value)}
              />
            </Stack>
          </Box>
        )}

        {currentStep === 1 && (
          <Box sx={{ width: "100%" }}>
            <Stack gap={2}>
              {isProcessingNetwork && (
                <>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{ display: "flex", justifyContent: "center" }}
                    p={2}
                  >
                    Processing atium SPM source. Please wait...
                  </Typography>
                  <LinearProgress />
                </>
              )}
              {!isProcessingNetwork && (
                <CodeMirror
                  value={
                    genieNetworkObject
                      ? JSON.stringify(genieNetworkObject, null, 2)
                      : ""
                  }
                  height="500px"
                  extensions={[json()]}
                  readOnly
                />
              )}
            </Stack>
          </Box>
        )}

        {currentStep === 2 && (
          <Box sx={{ width: "100%" }}>
            <Stack gap={2}>
              <Stack direction="row" gap={2}>
                <TextField
                  value={organizationId}
                  label="Organization ID"
                  disabled={true}
                />
                <TextField
                  value={networkId}
                  onChange={(e) => setNetworkId(e.target.value.trim())}
                  label="Network ID"
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isValidToSubmit && !isSaving}
                  onClick={handleSubmit}
                >
                  {isSaving ? "SAVING..." : "OVERWRITE"}
                </Button>
              </Stack>
              <CodeMirror
                value={
                  genieNetworkObject
                    ? JSON.stringify(genieNetworkObject, null, 2)
                    : ""
                }
                height="400px"
                extensions={[json()]}
                readOnly
              />
            </Stack>
          </Box>
        )}
        <Stack gap={2} direction="row" justifyContent="space-between">
          <Button
            disabled={currentStep < 1}
            onClick={() => setCurrentStep((prev) => prev - 1)}
          >
            <Typography variant="body2">PREVIOUS</Typography>
          </Button>
          <Button
            disabled={
              currentStep >= STEPS.length - 1 ||
              error !== null ||
              isProcessingNetwork
            }
            onClick={() =>
              setCurrentStep((prev) => {
                if (prev === 0) {
                  setGenieNetworkObject(undefined);
                }

                return prev + 1;
              })
            }
          >
            <Typography variant="body2">NEXT</Typography>
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default AtiumSPMMigrationTool;
