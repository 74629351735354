export type ArrowHeadType = "none" | "triangle";

export enum NodeType {
  Organization = "Organization",
  CustomOrganization = "CustomOrganization",
  Person = "Person",
  CustomPerson = "CustomPerson",
  TeamMember = "TeamMember",
  CustomTeamMember = "CustomMember",
  Source = "Source",
  CustomSource = "CustomSource",
  Touchpoint = "Touchpoint",
  EngagementPlan = "EngagementPlan",
  Text = "Text",
  Unknown = "Unknown",
}

export interface IGraphItem {
  id: string;
}

export type ScaleOption = "1x" | "2x" | "3x" | "4x" | "5x";
export const ScaleToNumberMap: Record<ScaleOption, number> = {
  "1x": 1,
  // NOTE: We want to increase the circle area to x time. Which mean that we need to scale the radius square root of x amount.
  "2x": Math.sqrt(2),
  "3x": Math.sqrt(3),
  "4x": Math.sqrt(4),
  "5x": Math.sqrt(5),
};

export interface IDataNodeProps {
  position?: [number, number];
  size?: number;
  scale?: ScaleOption;
  borderWidth?: number;
  domainId?: string;
  domainLabel?: string;
  labelWidth?: number;
  imageUrl?: string;
  imageHeight?: number;
  imageWidth?: number;
  borderColor?: string;
  backgroundColor?: string;
}

export interface IBaseDataNode extends IGraphItem, IDataNodeProps {
  type: NodeType;
  metadata?: { [key: string]: any };
}

export interface ICustomContentNode extends IBaseDataNode {
  type: NodeType.CustomSource;
  contentUrl: string;
}

export interface ICustomUserNode extends IBaseDataNode {
  type: NodeType.CustomTeamMember;
  email?: string;
}

export interface ICustomStakeholderNode extends IBaseDataNode {
  type: NodeType.CustomPerson | NodeType.CustomOrganization;
  headline: string;
}

export type IDataNode =
  | IBaseDataNode
  | ICustomContentNode
  | ICustomStakeholderNode
  | ICustomUserNode;

export interface IDataUiComponentProps {
  position?: [number, number];
  domainId?: string;
  content?: string;
  domainLabel?: string;
  width?: number;
  height?: number;
  textColor?: string;
  fontSize?: number;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  align?: string;
}

export interface IDataUiComponent
  extends IGraphItem,
    Partial<IDataUiComponentProps> {
  // Currently only supported text ui component
  type: "Text";
}

export interface IDataLinkProps {
  label?: string;
  domainId?: string;
  labelColor: string;
  labelOffset: number;
  color: string;
  opacity: number;
  width: number;

  pointerBegin: ArrowHeadType; // Default should be null
  pointerEnd: ArrowHeadType; // Default should be triangle

  // We use the same control point for the start and the end of the link
  // For more information, visit https://konvajs.org/docs/sandbox/Modify_Curves_with_Anchor_Points.html
  // IMPORTANT: Because the link begin and end position depend on the source and target position
  // We can not fix control points position. Instead, theses control points must be derived from a fixed set of parameters.
  // If we consider the link to be a vector v1
  // Then the control points is derived from:
  // 1. A vector perpendicular with v1. Which start from one third of the start or end point.
  // 2. Follow the right hand rule
  // 3. Have an offset vector (For when user drag the line, which we will use to change this offset)
  bezierControlPoints:
    | {
        size: number;
        startOffset: [number, number];
        endOffset: [number, number];
      }
    | "none";

  // For more information, visit https://konvajs.org/api/Konva.Shape.html#dash
  dash: [number, number] | "none";
}

export interface IDataLink extends IGraphItem, Partial<IDataLinkProps> {
  source: string;
  target: string;
}

export interface IDataGroupProps {
  label: string;
  color: string;
}

export interface IDataGroup extends IGraphItem, Partial<IDataGroupProps> {
  nodesIds: { [nodeId: string]: true };
}

export interface IGraphUserPreferences {
  showGrid?: boolean;
}

export interface IGraphData {
  nodes: IDataNode[];
  links: IDataLink[];
}

export interface IDataMetadata {
  lastThumbnailTimestamp?: number;
}

export interface INetworkGraphData {
  nodes?: { [key: string]: IDataNode };
  links?: { [key: string]: IDataLink };
  groups?: { [groupId: string]: IDataGroup };
  uiComponents?: { [key: string]: IDataUiComponent };
  metadata?: IDataMetadata;
}

export interface INetworkGraphPermissions {
  organization: string[];
  workspaces: { [key: string]: string[] };
  users: { [key: string]: string[] };
}

export interface INetworkGraphDoc {
  permissions: INetworkGraphPermissions;
  data?: INetworkGraphData;
}
